.motto {
  margin: 6rem 4rem 2rem 1rem;
  align-self: center;
  max-width: 100%;
}

#other-work {
  margin-top: 2rem;
}

#contact {
  margin: 2rem;
  align-items: center;
}

@media (max-width: 35em) {
  .motto {
    padding: 0 0 0 0;
    margin: 2rem;
  }

  h2 {
    font-size: 1rem;
  }

  #other-work {
    margin-top: 2rem;
  }
  #contact {
    align-items: center;
  }
}
