.sidebar {
  color: #878383;
  font-weight: 300;
  font-size: 0.8rem;
  position: fixed;
  left: 3rem;
  top: 33%;
  width: 5rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  z-index: 99;
}

.sidebar-item {
  cursor: pointer;
}

.sidebar-item.active {
  font-weight: bold;
  font-size: 1rem;
  color: var(--color);
}

@media (max-width: 67em) {
  .sidebar {
    display: none;
  }
}

@media (max-width: 35em) {
}
