.passioncy-background {
  background: linear-gradient(
    225deg,
    rgba(79, 197, 235, 0.2) 0%,
    rgba(93, 82, 217, 0.2) 100%
  );
}

.roversea {
  --color: #af926f;
}

.orelax {
  --color: #e7f6ff;
}

.orelax p {
  font-size: 125%;
  line-height: 1.5rem;
  font-weight: 300;
}

.passioncy {
  --color: #7638fa;
}

.drayeasy {
  --color: #2d84f9;
}
.drayeasy-map {
  margin: 2rem 0;
}
.textale {
  --color: #000000;
}
.onedeal {
  --color: #0e9384;
}
.w-container {
  width: 49rem;
  margin-left: auto;
  margin-right: auto;
}

.w-container:before,
.w-container:after {
  content: " ";
  grid-area: 1 / 1 / 2 / 2;
  display: table;
}

.w-container:after {
  clear: both;
}

.lightbox2-img {
  width: 100%;
  box-shadow: 0 0 20px -12px #e7e2dc;
}

.interview {
  max-width: 48%;
}

.lightbox1 {
  grid-column-gap: 40px;
  justify-content: center;
  margin-top: 60px;
  margin-bottom: 60px;
  display: flex;
}

.roversea-grid {
  grid-column-gap: 2rem;
  grid-row-gap: 2rem;
  grid-template:
    "Area Area-2"
    "Area-3 Area-4"
    "Area-5 Area-6"
    "Area-7 Area-8"
    / 0.5fr 0.75fr;
  grid-auto-columns: 1fr;
  justify-items: center;
  margin-top: 4rem;
  display: grid;
}

#grid1 {
  grid-area: Area;
}

#grid2 {
  grid-area: Area-3;
}

#grid3 {
  grid-area: Area-5;
}

#grid4 {
  grid-area: Area-7;
}

#text1 {
  grid-area: Area-2;
}

#text2 {
  grid-area: Area-4;
}
#text3 {
  grid-area: Area-6;
}
#text4 {
  grid-area: Area-8;
}

.lightbox2 {
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 4rem;
  margin-bottom: 4rem;
  text-decoration: none;
  display: flex;
}

.image-top-4rem {
  width: 100%;
  margin-top: 4rem;
}

.lighbox3-img3 {
  width: 19%;
}

.secondary-button {
  background-color: rgba(0, 0, 0, 0);
  margin-bottom: 0.5em;
  margin-left: 2.03em;
  margin-right: 2.03em;
  overflow: hidden;
}

.note {
  text-align: center;
  margin-top: 0.5em;
  font-style: normal;
  text-decoration: none;
}

.scroll-parent {
  width: 42%;
  justify-content: center;
  align-items: center;
  margin-left: 1%;
  margin-right: 1%;
  display: flex;
  position: relative;
  overflow: hidden;
}

.lighbox3-img {
  width: 38%;
  height: 99.9999%;
}

.roversea-grid-text {
  padding-top: 2rem;
  font-size: 1.5rem;
}

.lighbox3-img2 {
  width: 38%;
  height: 50%;
}

.roversea-userflow {
  width: 100%;
  margin-top: 4rem;
}

.heading-top-4rem {
  margin-top: 4rem;
}

.section_dark {
  background-color: var(--color);
  padding-top: 6rem;
  padding-bottom: 6rem;
}

.section_light {
  padding-top: 6rem;
  padding-bottom: 6rem;
}

.roversea-grid-img {
  max-width: 80%;
}

.persona-img {
  box-shadow: 0 0 16px -5px rgba(0, 0, 0, 0.2);
}

.lightbox3 {
  justify-content: space-between;
  display: flex;
}

.image-2rem {
  width: 100%;
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.image-4rem {
  width: 100%;
  margin-top: 4rem;
  margin-bottom: 4rem;
}

@media screen and (min-width: 1280px) {
  .roversea-grid {
    padding-top: 60px;
  }

  .lightbox2 {
    margin-bottom: 100px;
  }
}

@media screen and (max-width: 479px) {
  .paragraph-12 {
    font-size: 16px;
  }

  .roversea-grid {
    padding-top: 40px;
  }

  .note {
    font-size: 0.6rem;
  }

  .scroll-parent {
    width: 23.9%;
    height: 100%;
    margin-left: 1.5em;
    margin-right: 1.5em;
  }

  .roversea-grid-text {
    font-size: 16px;
  }

  .roversea-userflow {
    width: 100%;
    margin-top: 40px;
  }

  .heading-9 {
    margin-top: 40px;
    font-size: 24px;
  }
}
