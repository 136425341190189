/* 项目列表 */

.card-project {
  display: grid;
  grid-column-gap: 0.625rem;
  grid-row-gap: 0.625rem;
  flex-direction: row;
  grid-template:
    "area"
    / 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-auto-columns: 1fr;
  grid-auto-flow: dense;
  justify-content: center;
  align-items: start;
  margin: 4rem 0 4rem 0;
}

.card-box {
  border-radius: 1.2rem;
  box-shadow: 0 0 1.5625rem rgba(0, 0, 0, 0.18);
  max-width: 95%;
}

.card-box:hover {
  transition: 500ms;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.18), 0 0 20px 0 rgba(0, 0, 0, 0.18);
}

.clickable {
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  position: absolute;
  z-index: 1;
  cursor: pointer;
}

#img1 {
  grid-area: 1/1/2/9;
}

#txt1 {
  grid-area: 1/7/2/14;
}

.card-text {
  display: flex;
  flex-direction: column;
  align-items: end;
  position: relative;
  margin: 4rem 3rem 0 0;
}

.text-box {
  text-align: end;
  background-color: #fbf8f4;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
  padding: 2rem;
}

.left-box {
  align-items: start;
}

.left-text {
  text-align: start;
}

#project-title {
  margin: 0.5rem 0 0.5rem 0;
}

.red-text {
  color: #e44120;
}

.btn-1 {
  position: relative;
  margin-top: 12rem;
}

.card-image {
  max-width: auto;
  height: 100%;
  object-fit: cover;
  z-index: -1;
  object-fit: cover;
}

@media (max-width: 67em) {
  .btn-1 {
    margin-top: 5rem;
  }
}

@media (max-width: 35em) {
  .btn-1 {
    display: none;
  }

  .card-project {
    margin: 1rem 0;
    padding: 1rem;
  }

  .card-text {
    margin: 1rem;
  }

  #txt1 {
    text-align: end;
  }

  .mobile-show {
    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: center;
    justify-content: center;
  }
}
