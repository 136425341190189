/************************/
/* Picture Comparison*/
/************************/



.image-container {
    display: table;
  max-width: 100%;
  max-height: 90vh;
  aspect-ratio: 16/9;
  
  }
  
  .slider-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: left;
  }
  
  .image-before {
  position: absolute;
  inset: 0;
  width: var(--position);
  }
  
  .image-after{
    height: 100%;
  }
  
  .slider {
  position: absolute;
  inset: 0;
  cursor: pointer;
  opacity: 0;
  /* for Firefox */
  width: 100%;
  height: 100%;
  }
  
  .slider:focus-visible ~ .slider-button {
  outline: 5px solid black;
  outline-offset: 3px;
  }
  
  .slider-line {
  position: absolute;
  inset: 0;
  width: .2rem;
  height: 100%;
  background-color: #fff;
  /* z-index: 10; */
  left: var(--position);
  transform: translateX(-50%);
  pointer-events: none;
  }
  
  .slider-button {
  position: absolute;
  background-color: #fff;
  color: black;
  padding: .5rem;
  border-radius: 100vw;
  display: grid;
  place-items: center;
  top: 50%;
  left: var(--position);
  transform: translate(-50%, -50%);
  pointer-events: none;
  /* z-index: 100; */
  box-shadow: 1px 1px 1px hsl(0, 50%, 2%, .5);
  }
  