@import url("https://fonts.googleapis.com/css2?family=DM+Sans:wght@300;400;500;600;700&display=swap");

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "DM Sans";
}

*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

img {
  display: block;
  max-width: 100%;
}

main {
  display: grid;
  place-items: center;
  min-height: 100vh;
}

body {
  font-family: DM Sans;
  font-weight: 400;
}

h1 {
  font-size: 3rem;
  font-weight: 600;
}

h2 {
  font-size: 2rem;
  font-weight: 500;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  overflow: hidden;
  margin-left: auto;
  margin-right: auto;
  padding: auto;
  width: 61.25rem;

  --position: 50%;
}

.mobile-show {
  display: none;
}

/**************************
/* contact */
/**************************/

.contact-icon {
  margin: 2rem 0;
  font-size: 1.3125rem;
}

#mail {
  margin-left: 1rem;
}

/**************************
/* MeidaQuery */
/**************************/

@media (max-width: 67em) {
  .container {
    max-width: 45rem;
  }

  #img1 {
    grid-area: 1/1/2/8;
  }

  .card-box {
    max-width: 90%;
  }
}

@media (max-width: 35em) {
  .mobile {
    display: none;
  }

  .container {
    width: 87.5%;
  }

  p {
    font-size: 75%;
  }

  .link-no-underline {
    font-size: 75%;
  }
}
