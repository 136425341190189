/**************************
/* footer */
/**************************/

.footer {
  margin: 8rem 0 4rem 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  overflow: hidden;
  margin-left: auto;
  margin-right: auto;
  padding: auto;
  width: 100%;
}

@media (max-width: 35em) {
  .contact-icon {
    margin: 0.5rem 0 2rem 0;
  }

  .footer {
    max-width: 80vw;
    padding-left: 0;
  }
}
