.template-global p {
  font-size: 125%;
  line-height: 2rem;
  font-weight: 300;
}

.template-global h2 {
  font-size: 2rem;
  font-weight: 700;
  margin-top: 2rem;
  margin-bottom: 1rem;
}

.template-global h3 {
  font-size: 1.5rem;
  margin: 2rem 0;
  color: var(--color);
}

.template-global h4 {
  font-weight: 700;
  font-size: 1.2rem;
}

.template-global h1 {
  margin: 2rem 0 1rem 0;
  font-size: 3rem;
}

.template-global {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  justify-content: center;
}

.heading-image {
  width: 100%;
}

.heading-image2 {
  max-width: 100%;
  margin: 6rem 0;
}

.project-info {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  gap: 3rem;
  width: 100%;
}

.project-info h4 {
  margin-bottom: 1.5rem;
}

.new-line {
  max-width: 10rem;
  white-space: pre-line;
}

.data {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 2rem 0 4rem 0;
  width: 100%;
}

.data div {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  width: 40%;
}

.before-after {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 2rem;
}

.final-design {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 4rem;
}

.final-design div {
  width: 35%;
}

.final-design img {
  width: 55%;
}

.template {
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: start;
  align-content: start;
}

.colored-template {
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: start;
  align-content: start;
  width: 100%;
}

.solution-div {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 4rem;
  align-items: center;
  align-content: center;
  gap: 5rem;
}

.solution-text {
  display: flex;
  flex-direction: column;
  width: 40%;
}

.solutionImg {
  width: 30%;
  position: relative;
}

.separator {
  border: 1px solid #e0e0e0;
  width: 100%;
}

@media (max-width: 67em) {
}

@media (max-width: 35em) {
  .template-global p {
    font-size: 100%;
  }

  .project-info {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    gap: 0;
  }

  .project-info p {
    font-size: 0.75rem;
  }

  .project-info h4 {
    font-size: 0.75rem;
  }

  .heading-image2 {
    margin: 2rem;
  }

  .data {
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    align-content: center;
  }

  .data div {
    width: 100%;
  }

  .final-design {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 2rem;
  }

  .final-design div {
    width: 100%;
  }

  .final-design img {
    width: 100%;
  }
}
