.about p {
  font-size: 87.5%;
  line-height: 1.3rem;
}
.about .container {
  padding: 1rem;
  width: 60rem;
}

.about h3 {
  font-size: 150%;
}

.about-grid {
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  grid-template-rows: repeat(1, minmax(0, 1fr)); /* 竖着有4个row，自动拓展 */
  gap: 2rem;
  justify-items: center; /* 让子项在网格中水平居中 */
}

.about-grid a {
  text-decoration: none;
  color: black;
  font-size: 87.5%;
  line-height: 1.3rem;
}

.about-grid-item {
  aspect-ratio: 1;
  width: 100%;
  border-radius: 2rem;
  border: 0 solid #bdbdbd; /* 1px的黑色描边 */
  overflow: hidden; /* 隐藏超出item区域的内容，以确保边框的完整性 */
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.1);
}
.about-grid-item h3 {
  margin-bottom: 1rem;
}
.about-grid-item img {
  width: 100%;
  height: 100%;
  object-fit: cover; /* 让图片填充整个item，并保持宽高比 */
  overflow: hidden;
}

#about-grid-item-1 {
  grid-column: 1/5;
}

#about-grid-item-2 {
  grid-column: 5/9;
}

#about-grid-item-3 {
  grid-column: 1/5;
  aspect-ratio: 2;
  position: relative;
}

#about-grid-item-3 p {
  position: absolute;
  bottom: 1.5rem;
  left: 2rem;
}

#about-grid-item-4 {
  grid-column: 5/9;
  aspect-ratio: 2;
}

#about-grid-item-5 {
  aspect-ratio: 2.33134869;
  padding: 2rem 2rem 0px;
}
#about-grid-item-6 {
  aspect-ratio: 2.33134869;
}

#about-grid-item-7 {
  grid-column: 5/9;
  aspect-ratio: 1;
}

.about-subgrid-row {
  grid-column: 1/5;
  grid-row: span 1;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(0, 1fr);
  grid-gap: 2rem;
  justify-items: center;
}

.about-subgrid-row div {
  width: 100%;
  height: 100%;
  border-radius: 2rem;
  border: 0 solid #bdbdbd; /* 1px的黑色描边 */
  overflow: hidden; /* 隐藏超出item区域的内容，以确保边框的完整性 */
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.1);
}

#about-grid-item-8 {
  grid-column: 1/5;
  aspect-ratio: 2;
}
#about-grid-item-9 {
  grid-column: 5/9;
  aspect-ratio: 2;
}

#about-grid-item-10 {
  grid-column: 1/3;
  aspect-ratio: 1;
}

#about-grid-item-11 {
  grid-column: 3/5;
  aspect-ratio: 1;
}

#about-grid-item-12 {
  grid-column: 5/7;
  aspect-ratio: 1;
}

#about-grid-item-13 {
  grid-column: 7/9;
  aspect-ratio: 1;
}

@media (max-width: 67em) {
}

@media (max-width: 35em) {
  .about .container {
    width: auto;
    margin: 0 2rem 0 2rem;
  }

  .about-grid {
    display: grid;
    grid-template-columns: repeat(8, 1fr);
    grid-template-rows: repeat(1, 1fr); /* 竖着有4个row，自动拓展 */
    gap: 2rem;
    justify-items: center; /* 让子项在网格中水平居中 */
  }

  .about-grid-item {
    aspect-ratio: auto;

    width: 100%;
    border-radius: 2rem;
    border: 0 solid #bdbdbd; /* 1px的黑色描边 */
    overflow: hidden; /* 隐藏超出item区域的内容，以确保边框的完整性 */
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.1);
  }

  #about-grid-item-1,
  #about-grid-item-2,
  #about-grid-item-3,
  #about-grid-item-4,
  #about-grid-item-7,
  #about-grid-item-8,
  #about-grid-item-9,
  #about-grid-item-10,
  #about-grid-item-11,
  #about-grid-item-12,
  #about-grid-item-13 {
    grid-column: 1 / span 8;
    aspect-ratio: auto;
  }
  #about-grid-item-5 {
    padding: 2rem;
    aspect-ratio: auto;
  }

  #about-grid-item-6 {
    aspect-ratio: auto;
  }
  .about-subgrid-row {
    grid-column: 1 / span 8;
  }

  .heading-image2 {
    margin: 2rem;
  }
}
