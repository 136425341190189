/* 卡片*/

.container-project {
  margin-top: 3rem;
}

.project-container {
  margin-top: 4rem;
  align-content: center;
  align-items: flex-start;
  margin-left: 2%;
  margin-right: 2%;
  height: 100%;
  width: 46%;
  border: solid;
  border-radius: 1rem;
  border-color: #bdbdbd;
  border-width: 0rem;
  height: auto;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.1);
}

.project-container:hover {
  transition: 500ms;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.18), 0 6px 20px 0 rgba(0, 0, 0, 0.18);
}

.project-item-image {
  border-radius: 1.2rem 1.2rem 0 0;
  max-width: 100%;
  height: auto;
}

.item-text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 2rem;
}

.middle-text {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.see-all {
  text-decoration: none;
  transition: 0.3s;
  font-size: 1.3125rem;
  margin: 4rem 0;
}

.see-all:hover,
.see-all:active {
  color: #e44120;
}

.link-no-underline2 {
  text-decoration: none;
  color: black;
}

.mobile-hide {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  align-content: center;
}

@media (max-width: 67em) {
  .btn-1 {
    margin-top: 5rem;
  }
}

@media (max-width: 35em) {
  .see-all {
    margin: 0 0 2rem 0;
  }

  .mobile-hide {
    display: none;
  }
}
