/*.motto::after{
    content: "";
    position: absolute;
    top: -300px;
    right: -300px;
    bottom: -300px;
    left: -300px;
    background: radial-gradient(124.86% 69.53% at 77.47% 32.15%, rgba(233, 240, 187, 0.43) 20.83%, rgba(192, 244, 255, 0.49) 100%);
    z-index: -1;
    filter: blur(5px);
}




/************************/
/* Navigation Bar */
/************************/

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 5em;
  padding-left: 6rem;
  padding-right: 6rem;
  max-width: 100%;
  position: relative;
  box-sizing: border-box;
  background-color: transparent;
}

.logo {
  height: 2.4em;
}

.main-nav {
  display: flex;
  flex-direction: row;
  gap: 3.2rem;
}

.main-nav li {
  list-style: none;
}

.link-no-underline {
  text-decoration: none;
  color: black;
}

.link-no-underline:hover {
  color: #e44120;
  transition: 0.3s;
}

.main-nav-list:link,
.main-nav-list:visited {
  text-decoration: none;
  color: black;
  font-weight: 300;
  font-size: 1rem;
  transition: 0.3s;
}

.main-nav-list:hover,
.main-nav-list:active {
  color: #e44120;
}

/************************/
/*mobile nav*/
/************************/

.btn-mobile-nav {
  border: none;
  background: none;
  cursor: pointer;
  display: none;
}

.icon-mobile-nav {
  height: 4.8rem;
  width: 4.8rem;
  color: black;
}

@media (max-width: 55em) {
  .btn-mobile-nav {
    display: block;
    z-index: 1;
  }

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 5em;
    padding-left: 3rem;
    padding-right: 3rem;
    max-width: 100%;
    position: relative;
    box-sizing: border-box;
  }

  .main-nav {
    position: absolute;
    background-color: white;
    left: 0;
    top: 0;
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: -3;
    opacity: 0;
    transition: 0.5s;
  }

  .main-nav.active {
    opacity: 1;
    z-index: 1;
  }
}
