.portfolio-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(auto-fill, 1fr);
  gap: 4rem;
}

.grid-item-link {
  text-decoration: none;
  color: black;
}

@media (max-width: 35em) {
  .portfolio-grid {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: repeat(auto-fill, 1fr);
    gap: 4rem;
  }

  .grid-item h3 {
    max-width: 90%;
    margin-left: 2rem;
  }

  .grid-item p {
    max-width: 90%;
    margin-left: 2rem;
  }
}
